<template>
  <div class="card">
    <b-card-body>
      <b-form-group
        label="Pilih Pasar:"
        label-cols-md="3"
        label-align-sm="right"
        class="mb-0"
      >
        <v-select
          v-model="datapemantauan.id_pasar"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :reduce="(paginated) => paginated.id"
          :options="datapasar"
          @input="cekpasar(datapemantauan.id_pasar)"
        />
      </b-form-group>
    </b-card-body>
    <p class="ml-2 mb-1"><b>Silahkan masukkan data ketersediaan dan harga Komoditas Sembako per Kilogram(kg)</b></p>

    <!-- table -->
    <!-- <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>

      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
    </b-table> -->
    <div class="table-responsive-sm">
    <table class="table">
      <thead>
        <tr class="table-active">
          <th>No</th>
          <th>Komoditi</th>
          <th>Ketersediaan</th>
          <th>Harga</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Beras</td>
          <td><b-form-select style="width:150px" v-model="datapemantauan.ketersediaan_beras" :options="option_ketersediaan"></b-form-select></td>
          <td>
              <b-input-group
                prepend="Rp"
                style="width:150px"
                class="input-group-merge"
              >
            <b-form-input type="text" v-model="datapemantauan.harga_beras" placeholder="0" />
          </b-input-group>
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>Minyak Goreng</td>
          <td><b-form-select style="width:150px" v-model="datapemantauan.ketersediaan_minyak_goreng" :options="option_ketersediaan"></b-form-select></td>
          <td>
              <b-input-group
                prepend="Rp"
                style="width:150px"
                class="input-group-merge"
              >
            <b-form-input v-model="datapemantauan.harga_minyak_goreng" placeholder="0" />
          </b-input-group>
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>Telur</td>
          <td><b-form-select style="width:150px" v-model="datapemantauan.ketersediaan_telur" :options="option_ketersediaan"></b-form-select></td>
          <td>
              <b-input-group
                prepend="Rp"
                style="width:150px"
                class="input-group-merge"
              >
            <b-form-input v-model="datapemantauan.harga_telur" placeholder="0" />
          </b-input-group>
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>Gula Pasir</td>
          <td><b-form-select style="width:150px" v-model="datapemantauan.ketersediaan_gula" :options="option_ketersediaan"></b-form-select></td>
          <td>
              <b-input-group
                prepend="Rp"
                style="width:150px"
                class="input-group-merge"
              >
            <b-form-input v-model="datapemantauan.harga_gula" placeholder="0" />
          </b-input-group>
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>Daging Ayam</td>
          <td><b-form-select style="width:150px" v-model="datapemantauan.ketersediaan_daging_ayam" :options="option_ketersediaan"></b-form-select></td>
          <td>
              <b-input-group
                prepend="Rp"
                style="width:150px"
                class="input-group-merge"
              >
            <b-form-input v-model="datapemantauan.harga_daging_ayam" placeholder="0" />
          </b-input-group>
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>Daging Sapi</td>
          <td><b-form-select style="width:150px" v-model="datapemantauan.ketersediaan_daging_sapi" :options="option_ketersediaan"></b-form-select></td>
          <td>
              <b-input-group
                prepend="Rp"
                style="width:150px"
                class="input-group-merge"
              >
            <b-form-input v-model="datapemantauan.harga_daging_sapi" placeholder="0" />
          </b-input-group>
          </td>
        </tr>
        <tr>
          <td>7</td>
          <td>Bawang Merah</td>
          <td><b-form-select style="width:150px" v-model="datapemantauan.ketersediaan_bawang_merah" :options="option_ketersediaan"></b-form-select></td>
          <td>
              <b-input-group
                prepend="Rp"
                style="width:150px"
                class="input-group-merge"
              >
            <b-form-input v-model="datapemantauan.harga_bawang_merah" placeholder="0" />
          </b-input-group>
          </td>
        </tr>
        <tr>
          <td>8</td>
          <td>Kedelai</td>
          <td><b-form-select style="width:150px" v-model="datapemantauan.ketersediaan_kedelai" :options="option_ketersediaan"></b-form-select></td>
          <td>
              <b-input-group
                prepend="Rp"
                style="width:150px"
                class="input-group-merge"
              >
            <b-form-input v-model="datapemantauan.harga_kedelai" placeholder="0" />
          </b-input-group>
          </td>
        </tr>
        <tr>
          <td>9</td>
          <td>Cabe Merah</td>
          <td><b-form-select style="width:150px" v-model="datapemantauan.ketersediaan_cabe_merah" :options="option_ketersediaan"></b-form-select></td>
          <td>
              <b-input-group
                prepend="Rp"
                style="width:150px"
                class="input-group-merge"
              >
            <b-form-input v-model="datapemantauan.harga_cabe_merah" placeholder="0" />
          </b-input-group>
          </td>
        </tr>
      </tbody>
      </table>
    </div>
    <b-row>
      <!-- submit and reset -->
      <b-col offset-md="4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1 mb-1"
          style="float: right;"
          @click="simpandata()"
        >
          Submit
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody,BFormSelect,BInputGroup,BFormInput, BRow, BCol
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { codeRowSelectSupport } from './code'
import axios from 'axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from "vue";

export default {
  components: {
    // BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      url: localStorage.getItem('baseapi'),
      user:JSON.parse(localStorage.getItem('userData')),
      modes: ['multi', 'single', 'range'],
      fields: ['id', { key: 'avatar', label: 'Avatar' }, 'full_name', 'post', 'email', 'city', 'start_date', 'salary', 'age', 'experience', { key: 'status', label: 'Status' }],
      /* eslint-disable global-require */
      option_ketersediaan: [
        { value: 4, text: 'Lebih' },
        { value: 3, text: 'Cukup' },
        { value: 2, text: 'Kurang' },
        { value: 1, text: 'Kosong' },
    ],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],
      codeRowSelectSupport,
      datapasar:[],
      datapemantauan:[],
      datastok:[],
      harga_beras:0,
      harga_minyak_goreng:0,
      harga_telur:0,
      harga_gula:0,
      harga_daging_ayam:0,
      harga_daging_sapi:0,
      harga_bawang_merah:0,
      harga_kedelai:0,
      harga_cabe_merah:0
    }
  },
  mounted() {
    this.loaddata()
  },
  watch: {
    "datapemantauan.harga_beras": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.datapemantauan.harga_beras = result));
      var totalbaru = result.split(".").join("");

      this.harga_beras = parseInt(totalbaru);
    },
    "datapemantauan.harga_minyak_goreng": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.datapemantauan.harga_minyak_goreng = result));
      var totalbaru = result.split(".").join("");

      this.harga_minyak_goreng = parseInt(totalbaru);
    },
    "datapemantauan.harga_telur": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.datapemantauan.harga_telur = result));
      var totalbaru = result.split(".").join("");

      this.harga_telur = parseInt(totalbaru);
    },
    "datapemantauan.harga_gula": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.datapemantauan.harga_gula = result));
      var totalbaru = result.split(".").join("");

      this.harga_gula = parseInt(totalbaru);
    },
    "datapemantauan.harga_daging_ayam": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.datapemantauan.harga_daging_ayam = result));
      var totalbaru = result.split(".").join("");

      this.harga_daging_ayam = parseInt(totalbaru);
    },
    "datapemantauan.harga_daging_sapi": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.datapemantauan.harga_daging_sapi = result));
      var totalbaru = result.split(".").join("");

      this.harga_daging_sapi = parseInt(totalbaru);
    },
    "datapemantauan.harga_bawang_merah": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.datapemantauan.harga_bawang_merah = result));
      var totalbaru = result.split(".").join("");

      this.harga_bawang_merah = parseInt(totalbaru);
    },
    "datapemantauan.harga_kedelai": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.datapemantauan.harga_kedelai = result));
      var totalbaru = result.split(".").join("");

      this.harga_kedelai = parseInt(totalbaru);
    },
    "datapemantauan.harga_cabe_merah": function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => (this.datapemantauan.harga_cabe_merah = result));
      var totalbaru = result.split(".").join("");

      this.harga_cabe_merah = parseInt(totalbaru);
    },
  },
  methods: {
    loaddata(){
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      axios.get(this.url+'stok?filter=user_id,=,'+this.user.id+';tanggal_pelaporan,=,'+date, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        // const { users, total } = response.data.data
        var hasil = []
        hasil  = response.data.data
        for (let index = 0; index < hasil.length; index++) {
              this.datastok.push(parseInt(hasil[index].id_pasar))
            }
        console.log(this.datastok)
        this.getdatapasar()
      })
      .catch( error => {
        if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
          this.$swal({
            title: error.response.data,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
            }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem('userData')
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }
          })
        } else {
         this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
      })
    },
    getdatapasar(){
      axios.get(this.url+'pasar?filter=kode_kec,=,'+this.user.kode_kec+';kode_prov,=,'+this.user.kode_prov+';kode_kab,=,'+this.user.kode_kab, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        var data  = response.data.data
        for (let index = 0; index < data.length; index++) {
          // for (let index2 = 0; index2 < this.datastok.length; index2++) {
          //   if (parseInt(data[index].id) == this.datastok[index2]){
          //     continue;
          //   }
            this.datapasar.push({
                id: parseInt(data[index].id),
                label:data[index].nama_pasar+', '+ data[index].kabupaten,
            })
            // }
          }
          console.log(this.datapasar)
      })
      .catch( error => {
        if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
          this.$swal({
            title: error.response.data,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
            }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem('userData')
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }
          })
        } else {
         this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
      })
    },
    cekpasar(id){
      if(this.datastok.includes(id) == true) {
          this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: "Data sudah ada silahkan pilih pasar lainnya",
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                  },
                })
          this.datapemantauan = []
          console.log('woi')
        } else {
          console.log('woi')
        }
    },
    simpandata(){
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const user_id=this.user.id;
      const nama_user = this.user.nama;
      const tanggal_pelaporan=date;
      const id_pasar=this.datapemantauan.id_pasar;
      const ketersediaan_beras=this.datapemantauan.ketersediaan_beras;
      const harga_beras=this.harga_beras;
      const ketersediaan_minyak_goreng=this.datapemantauan.ketersediaan_minyak_goreng;
      const harga_minyak_goreng=this.harga_minyak_goreng;
      const ketersediaan_telur=this.datapemantauan.ketersediaan_telur;
      const harga_telur=this.harga_telur;
      const ketersediaan_gula=this.datapemantauan.ketersediaan_gula;
      const harga_gula=this.harga_gula;
      const ketersediaan_daging_ayam=this.datapemantauan.ketersediaan_daging_ayam;
      const harga_daging_ayam=this.harga_daging_ayam;
      const ketersediaan_daging_sapi=this.datapemantauan.ketersediaan_daging_sapi;
      const harga_daging_sapi=this.harga_daging_sapi;
      const ketersediaan_bawang_merah=this.datapemantauan.ketersediaan_bawang_merah;
      const harga_bawang_merah=this.harga_bawang_merah;
      const ketersediaan_kedelai=this.datapemantauan.ketersediaan_kedelai;
      const harga_kedelai=this.harga_kedelai;
      const ketersediaan_cabe_merah=this.datapemantauan.ketersediaan_cabe_merah;
      const harga_cabe_merah=this.harga_cabe_merah;

      if(this.datastok.length == 0){
            axios
                .post(
                this.url+"stok",
                {
                    user_id,
                    nama_user,
                    tanggal_pelaporan,
                    id_pasar,
                    ketersediaan_beras,
                    harga_beras,
                    ketersediaan_minyak_goreng,
                    harga_minyak_goreng,
                    ketersediaan_telur,
                    harga_telur,
                    ketersediaan_gula,
                    harga_gula,
                    ketersediaan_daging_ayam,
                    harga_daging_ayam,
                    ketersediaan_daging_sapi,
                    harga_daging_sapi,
                    ketersediaan_bawang_merah,
                    harga_bawang_merah,
                    ketersediaan_kedelai,
                    harga_kedelai,
                    ketersediaan_cabe_merah,
                    harga_cabe_merah
                },
                {
                headers: {
                    "Content-type": "application/json",
                    // "xth": this.token,
                    }
                }
                )
                .then((res) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Success`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `Berhasil`,
                        },
                      })
                    this.$router.push({ name: 'pemantauan-sembako' })
                return res;
                })
                .catch((err) => {
                this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: err.response.data.message,
                          icon: 'CoffeeIcon',
                          variant: 'danger',
                        },
                      })
                return err;
            });
      } else {
        if(this.datastok.includes(this.datapemantauan.id_pasar) == true) {
          this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: "Data sudah ada silahkan pilih pasar lainnya",
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                  },
                })
        } else {
          axios
                  .post(
                  this.url+"stok",
                  {
                      user_id,
                      nama_user,
                      tanggal_pelaporan,
                      id_pasar,
                      ketersediaan_beras,
                      harga_beras,
                      ketersediaan_minyak_goreng,
                      harga_minyak_goreng,
                      ketersediaan_telur,
                      harga_telur,
                      ketersediaan_gula,
                      harga_gula,
                      ketersediaan_daging_ayam,
                      harga_daging_ayam,
                      ketersediaan_daging_sapi,
                      harga_daging_sapi,
                      ketersediaan_bawang_merah,
                      harga_bawang_merah,
                      ketersediaan_kedelai,
                      harga_kedelai,
                      ketersediaan_cabe_merah,
                      harga_cabe_merah
                  },
                  {
                  headers: {
                      "Content-type": "application/json",
                      // "xth": this.token,
                      }
                  }
                  )
                  .then((res) => {
                      this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `Success`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: `Berhasil menyimpan data`,
                          },
                        })
                      this.$router.push({ name: 'pemantauan-sembako' })
                  return res;
                  })
                  .catch((err) => {
                  this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: err.response.data.message,
                            icon: 'CoffeeIcon',
                            variant: 'danger',
                          },
                        })
                  return err;
              });
        }
        // for (let index = 0; index < this.datastok.length; index++) {
        //   if (this.datapemantauan.id_pasar == this.datastok[index]){
        //     this.$toast({
        //           component: ToastificationContent,
        //           position: 'top-right',
        //           props: {
        //             title: "Data Pasar sudah didata",
        //             icon: 'CoffeeIcon',
        //             variant: 'danger',
        //           },
        //         })
        //   } else {
        //       axios
        //           .post(
        //           this.url+"stok",
        //           {
        //               user_id,
        //               nama_user,
        //               tanggal_pelaporan,
        //               id_pasar,
        //               ketersediaan_beras,
        //               harga_beras,
        //               ketersediaan_minyak_goreng,
        //               harga_minyak_goreng,
        //               ketersediaan_telur,
        //               harga_telur,
        //               ketersediaan_gula,
        //               harga_gula,
        //               ketersediaan_daging_ayam,
        //               harga_daging_ayam,
        //               ketersediaan_daging_sapi,
        //               harga_daging_sapi,
        //               ketersediaan_bawang_merah,
        //               harga_bawang_merah,
        //               ketersediaan_kedelai,
        //               harga_kedelai,
        //               ketersediaan_cabe_merah,
        //               harga_cabe_merah
        //           },
        //           {
        //           headers: {
        //               "Content-type": "application/json",
        //               // "xth": this.token,
        //               }
        //           }
        //           )
        //           .then((res) => {
        //               this.$toast({
        //                   component: ToastificationContent,
        //                   position: 'top-right',
        //                   props: {
        //                     title: `Success`,
        //                     icon: 'CoffeeIcon',
        //                     variant: 'success',
        //                     text: `Berhasil`,
        //                   },
        //                 })
        //               this.$router.push({ name: 'pemantauan-sembako' })
        //           return res;
        //           })
        //           .catch((err) => {
        //           this.$toast({
        //                   component: ToastificationContent,
        //                   position: 'top-right',
        //                   props: {
        //                     title: err.response.data.message,
        //                     icon: 'CoffeeIcon',
        //                     variant: 'danger',
        //                   },
        //                 })
        //           return err;
        //       });
        //   }
        // }
      }
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}

@media
	  only screen 
    and (max-width: 760px), (min-device-width: 768px) 
    and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
      margin: 10px 1rem 1rem 1rem;
    }
      
    tr:nth-child(odd) {
      background: #E1EDFF;
    }
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 50%;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}

		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
		td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Komoditi"; }
		td:nth-of-type(3):before { content: "Ketersediaan"; }
		td:nth-of-type(4):before { content: "Harga"; }
	}
</style>